body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: initial;
}

code {
}
  .active{
     color:red;
  }
