@import url(https://fonts.googleapis.com/css?family=Rozha+One|Montserrat);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-weight: initial;
}

code {
}
  .active{
     color:red;
  }

body {
  padding: 25px 45px 25px 45px;
  overflow: hidden;
  color: #333;
}
#root {
  height: 100vh;
}
